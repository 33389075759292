.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.content {
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
}

.list {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.priceInput {
  width: 80px;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  text-align: center;
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

/* Custom toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.addressInfo {
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 0.25rem;
  line-height: 1.2;
}