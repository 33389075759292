/* MapWithList.css */

/* General button styles */
header .dropdown-toggle,
header .btn-primary {
  font-size: 1rem;
  padding: 10px 20px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  header h1 {
    font-size: 1.2rem;
    margin: 0;
  }

  header .dropdown-wrapper,
  header .button-wrapper {
    flex-shrink: 0; /* Prevent items from growing and taking full width */
    margin: 0 5px; /* Add margin between items */
  }

  header .dropdown-toggle,
  header .btn-primary {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .toggle-button-wrapper {
    text-align: center;
    margin: 10px 0;
  }
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
}

.header-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  z-index: 0;
  font-family: 'Arial', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #007bff; /* Bootstrap primary blue */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 2px;
  background: linear-gradient(45deg, #007bff, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;
}

.header-title:hover {
  transform: translateX(-50%) scale(1.05);
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .header-container {
    padding-top: 50px; /* Increased to make more room for the larger title */
  }

  .header-title {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    font-size: 2rem; /* Slightly smaller on mobile, but still prominent */
  }
}
