.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* HTML marker styles */
.marker-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 20px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  margin-right: 5px; /* Adjust margin as needed */
}

/*
.price-tag {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}*/

.price-tag {
  background-color: #4285F4;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 5px 10px;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285F4;
}

.places-list {
  font-size: 14px; /* Set font size to make it smaller */
}

.places-list-header {
  background-color: #f8f9fa; /* Background color for header */
}

.place-name {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.place-name:hover {
  text-decoration: underline; /* Underline text on hover */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
